import React, { useState, useEffect } from "react";

function Login({ onLogin = () => {} }) {
  // Standardfunktion für onLogin
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []); // Dieser Effekt läuft nur beim ersten Rendern

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://docker-system.vx2.vj0.de/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("authToken", data.token); // Token im Local Storage speichern
        onLogin(data.token);
        setIsLoggedIn(true);
      } else {
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Error during login", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Token aus dem Local Storage entfernen
    setIsLoggedIn(false);
    onLogin(null);
  };

  if (isLoggedIn) {
    return <button onClick={handleLogout}>Logout</button>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />
      <button type="submit">Login</button>
    </form>
  );
}

export default Login;
