import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faServer } from "@fortawesome/free-solid-svg-icons";
import styles from "./Sidebar.module.css";

function Sidebar() {
  const location = useLocation();

  return (
    <aside className={styles.sidebar}>
      <nav>
        <ul>
          <li>
            <NavLink
              to="/server"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.link
              }
            >
              <FontAwesomeIcon icon={faServer} className={styles.icon} />
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/login"
              className={({ isActive }) =>
                isActive ? styles.activeLink : styles.link
              }
            >
              <FontAwesomeIcon icon={faHouse} className={styles.icon} />
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
}

export default Sidebar;
