import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Tile.module.css";

function Tile({ title, details, icon, onClick }) {
  return (
    <div className={styles.tile} onClick={onClick}>
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.details}>{details}</p>
    </div>
  );
}

export default Tile;
