// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__Lhnr8 {
  background-color: #ffffff; /* Change to white for better contrast */
  color: #343a40;
  padding: 20px;
  width: 100%;
  text-align: left; /* Linksbündig ausrichten */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slightly larger shadow for better distinction */
  font-family: "Arial", sans-serif; /* Serifenlose Schriftart */
  box-sizing: border-box;
  border-bottom: 1px solid #e0e0e0; /* Add a subtle bottom border */
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.Header_title__rzFD1 {
  margin: 0;
  font-size: 15px;
  font-weight: 500; /* Semi-bold für bessere Sichtbarkeit */
  letter-spacing: 1px; /* Abstand zwischen Buchstaben */
  text-transform: uppercase; /* Großbuchstaben */
}

.Header_goBackIcon__4MkqV {
  cursor: pointer;
  margin-right: 10px; /* Space between the icon and the title */
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,wCAAwC;EACnE,cAAc;EACd,aAAa;EACb,WAAW;EACX,gBAAgB,EAAE,2BAA2B;EAC7C,wCAAwC,EAAE,kDAAkD;EAC5F,gCAAgC,EAAE,2BAA2B;EAC7D,sBAAsB;EACtB,gCAAgC,EAAE,+BAA+B;EACjE,aAAa;EACb,mBAAmB,EAAE,yCAAyC;AAChE;;AAEA;EACE,SAAS;EACT,eAAe;EACf,gBAAgB,EAAE,uCAAuC;EACzD,mBAAmB,EAAE,gCAAgC;EACrD,yBAAyB,EAAE,mBAAmB;AAChD;;AAEA;EACE,eAAe;EACf,kBAAkB,EAAE,yCAAyC;AAC/D","sourcesContent":[".header {\n  background-color: #ffffff; /* Change to white for better contrast */\n  color: #343a40;\n  padding: 20px;\n  width: 100%;\n  text-align: left; /* Linksbündig ausrichten */\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slightly larger shadow for better distinction */\n  font-family: \"Arial\", sans-serif; /* Serifenlose Schriftart */\n  box-sizing: border-box;\n  border-bottom: 1px solid #e0e0e0; /* Add a subtle bottom border */\n  display: flex;\n  align-items: center; /* Align items vertically in the center */\n}\n\n.title {\n  margin: 0;\n  font-size: 15px;\n  font-weight: 500; /* Semi-bold für bessere Sichtbarkeit */\n  letter-spacing: 1px; /* Abstand zwischen Buchstaben */\n  text-transform: uppercase; /* Großbuchstaben */\n}\n\n.goBackIcon {\n  cursor: pointer;\n  margin-right: 10px; /* Space between the icon and the title */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__Lhnr8`,
	"title": `Header_title__rzFD1`,
	"goBackIcon": `Header_goBackIcon__4MkqV`
};
export default ___CSS_LOADER_EXPORT___;
