// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServerOverview_serverOverview__wOVnO {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #f8f9fa; /* Leicht grauer Hintergrund */
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.ServerOverview_searchBar__5UyNY {
  width: 30%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: grey;
}

.ServerOverview_searchBar__5UyNY:focus {
  border-color: rgb(
    11,
    104,
    175
  ); /* Change this color to your preferred color */
  outline: none; /* Remove the default outline */
}

.ServerOverview_tilesContainer__p7hHT {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ServerOverview.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,yBAAyB,EAAE,8BAA8B;EACzD,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE;;;;GAIC,EAAE,8CAA8C;EACjD,aAAa,EAAE,+BAA+B;AAChD;;AAEA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,WAAW;AACb","sourcesContent":[".serverOverview {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  background-color: #f8f9fa; /* Leicht grauer Hintergrund */\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n}\n\n.searchBar {\n  width: 30%;\n  padding: 10px;\n  margin-bottom: 20px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  font-size: 14px;\n  color: grey;\n}\n\n.searchBar:focus {\n  border-color: rgb(\n    11,\n    104,\n    175\n  ); /* Change this color to your preferred color */\n  outline: none; /* Remove the default outline */\n}\n\n.tilesContainer {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serverOverview": `ServerOverview_serverOverview__wOVnO`,
	"searchBar": `ServerOverview_searchBar__5UyNY`,
	"tilesContainer": `ServerOverview_tilesContainer__p7hHT`
};
export default ___CSS_LOADER_EXPORT___;
