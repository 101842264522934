import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tile from "./Tile";
import { getDockerServers } from "../api";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import styles from "./ServerOverview.module.css";

function ServerOverview() {
  const [servers, setServers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredServers, setFilteredServers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const data = await getDockerServers();
        setServers(data);
        setFilteredServers(data); // Initially show all servers
      } catch (error) {
        console.error("Error fetching servers:", error);
      }
    };

    fetchServers();
  }, []);

  useEffect(() => {
    const results = servers.filter((server) =>
      server.server_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredServers(results);
  }, [searchText, servers]);

  const handleTileClick = (serverName) => {
    navigate(`/server/${serverName}`);
  };

  return (
    <div className={styles.serverOverview}>
      <input
        type="text"
        placeholder="Search servers..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className={styles.searchBar}
      />
      <div className={styles.tilesContainer}>
        {filteredServers.map((server) => (
          <Tile
            key={server.server_id}
            title={server.server_name}
            details={`CPU: ${server.cpu_load_percentage}% | Disk: ${server.used_disk_space_gb}GB / ${server.total_disk_space_gb}GB | Containers: ${server.docker_container_count}`}
            icon={faDesktop}
            onClick={() => handleTileClick(server.server_name)}
          />
        ))}
      </div>
    </div>
  );
}

export default ServerOverview;
