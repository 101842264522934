import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tile from "./WebcrawlerTile";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import styles from "./WebcrawlerDetail.module.css";
import { getWebCrawlers } from "../api";

function WebcrawlerDetail() {
  const [data, setData] = useState({
    container_details: [],
    last_log_snippet: {},
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const incomingData = await getWebCrawlers(id);

        try {
          incomingData.container_details = JSON.parse(
            incomingData.container_details
          );
        } catch (error) {
          incomingData.container_details = [];
        }

        try {
          // Überprüfen, ob incomingData.last_log_snippet nicht null oder undefined ist
          incomingData.last_log_snippet = incomingData.last_log_snippet
            ? JSON.parse(incomingData.last_log_snippet)
            : {};
        } catch (error) {
          incomingData.last_log_snippet = {};
        }

        console.log(incomingData);
        setData(incomingData);
      } catch (error) {
        console.error("Error fetching crawlers:", error);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div className={styles.serverOverview}>
      <div className={styles.tilesContainer}>
        {data.container_details.map((crawler) => {
          const crawlerName = crawler.name;
          const log =
            data.last_log_snippet[crawlerName]?.logs || "Log not available";
          return (
            <Tile key={crawler.id} title={crawler.name} details={`${log}`} />
          );
        })}
      </div>
    </div>
  );
}

export default WebcrawlerDetail;
