// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WebcrawlerTile_tile__rywhV {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  text-align: left;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 600px; /* Angepasste Breite für einheitliche Kacheln */
}

.WebcrawlerTile_tile__rywhV:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.WebcrawlerTile_icon__Uk\\+eX {
  font-size: 48px;
  margin-bottom: 10px;
  color: #6c757d; /* Graues Icon */
}

.WebcrawlerTile_title__k0soo {
  margin: 10px 0;
  font-size: 14px;
  color: #333;
  font-weight: normal; /* Weniger fett */
  white-space: normal; /* Allow the text to wrap */
  word-wrap: break-word; /* Break words to fit within the container */
}

.WebcrawlerTile_details__tFo1X {
  height: 100px; /* Höhe des Kastens */
  padding: 10px; /* Innenabstand (optional) */
  border: 1px solid #ccc; /* Rahmen um den Kasten */
  overflow-y: auto; /* Ermöglicht vertikales Scrollen, wenn der Inhalt die Höhe überschreitet */
  overflow-x: hidden; /* Verhindert horizontales Scrollen */
  background-color: #f9f9f9; /* Hintergrundfarbe (optional) */
}
`, "",{"version":3,"sources":["webpack://./src/components/WebcrawlerTile.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,2CAA2C;EAC3C,YAAY,EAAE,+CAA+C;AAC/D;;AAEA;EACE,2BAA2B;EAC3B,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc,EAAE,gBAAgB;AAClC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,WAAW;EACX,mBAAmB,EAAE,iBAAiB;EACtC,mBAAmB,EAAE,2BAA2B;EAChD,qBAAqB,EAAE,4CAA4C;AACrE;;AAEA;EACE,aAAa,EAAE,qBAAqB;EACpC,aAAa,EAAE,4BAA4B;EAC3C,sBAAsB,EAAE,yBAAyB;EACjD,gBAAgB,EAAE,2EAA2E;EAC7F,kBAAkB,EAAE,qCAAqC;EACzD,yBAAyB,EAAE,gCAAgC;AAC7D","sourcesContent":[".tile {\n  background-color: #fff;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n  margin: 10px;\n  text-align: left;\n  cursor: pointer;\n  transition: transform 0.3s, box-shadow 0.3s;\n  width: 600px; /* Angepasste Breite für einheitliche Kacheln */\n}\n\n.tile:hover {\n  transform: translateY(-5px);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.icon {\n  font-size: 48px;\n  margin-bottom: 10px;\n  color: #6c757d; /* Graues Icon */\n}\n\n.title {\n  margin: 10px 0;\n  font-size: 14px;\n  color: #333;\n  font-weight: normal; /* Weniger fett */\n  white-space: normal; /* Allow the text to wrap */\n  word-wrap: break-word; /* Break words to fit within the container */\n}\n\n.details {\n  height: 100px; /* Höhe des Kastens */\n  padding: 10px; /* Innenabstand (optional) */\n  border: 1px solid #ccc; /* Rahmen um den Kasten */\n  overflow-y: auto; /* Ermöglicht vertikales Scrollen, wenn der Inhalt die Höhe überschreitet */\n  overflow-x: hidden; /* Verhindert horizontales Scrollen */\n  background-color: #f9f9f9; /* Hintergrundfarbe (optional) */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tile": `WebcrawlerTile_tile__rywhV`,
	"icon": `WebcrawlerTile_icon__Uk+eX`,
	"title": `WebcrawlerTile_title__k0soo`,
	"details": `WebcrawlerTile_details__tFo1X`
};
export default ___CSS_LOADER_EXPORT___;
