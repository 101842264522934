import React from "react";
import { Routes, Route } from "react-router-dom";
import ServerOverview from "./ServerOverview";
import ServerDetail from "./ServerDetail";
import WebcrawlerDetail from "./WebcrawlerDetail";
import Login from "./Login";
import styles from "./MainFrame.module.css";

function MainFrame({ onLogin }) {
  return (
    <main className={styles.mainFrame}>
      <Routes>
        <Route path="/server" element={<ServerOverview />} />
        <Route path="/server/:serverName" element={<ServerDetail />} />
        <Route path="/server/:serverName/:id" element={<WebcrawlerDetail />} />
        <Route path="/login" element={<Login onLogin={onLogin} />} />{" "}
        {/* handleLogin hier weitergeben */}
      </Routes>
    </main>
  );
}

export default MainFrame;
