import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import styles from "./Header.module.css";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const getTitle = () => {
    if (location.pathname === "/server") {
      return "Serverübersicht";
    } else if (location.pathname.startsWith("/server/")) {
      const serverName = location.pathname.split("/server/")[1];
      return `Server: ${serverName}`;
    } else {
      return "Dashboard";
    }
  };

  const handleGoBack = () => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.length > 2) {
      const newPath = pathSegments.slice(0, -1).join("/");
      navigate(newPath);
    } else {
      navigate("/server");
    }
  };

  return (
    <header className={styles.header}>
      <FontAwesomeIcon
        icon={faCircleLeft}
        className={styles.goBackIcon}
        onClick={handleGoBack}
      />{" "}
      {/* Add the go back icon */}
      <h1 className={styles.title}>{getTitle()}</h1>
    </header>
  );
}

export default Header;
