// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__TTYAt {
  background-color: #f8f9fa;
  color: #343a40;
  padding: 20px;
  width: 50px;
  height: 100vh;
  top: 0;
  left: 0;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.Sidebar_sidebar__TTYAt ul {
  list-style-type: none;
  padding: 0;
}

.Sidebar_sidebar__TTYAt li {
  margin: 20px 0;
}

.Sidebar_sidebar__TTYAt a {
  text-decoration: none;
}

.Sidebar_icon__1v8Ln {
  font-size: 24px;
}

.Sidebar_link__mVAGN .Sidebar_icon__1v8Ln {
  color: #6c757d; /* Standardfarbe der Icons */
}

.Sidebar_activeLink__NLkVB .Sidebar_icon__1v8Ln {
  color: rgb(11, 104, 175); /* Farbe der aktiven Icons */
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,WAAW;EACX,aAAa;EACb,MAAM;EACN,OAAO;EACP,wCAAwC;EACxC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc,EAAE,4BAA4B;AAC9C;;AAEA;EACE,wBAAwB,EAAE,4BAA4B;AACxD","sourcesContent":[".sidebar {\n  background-color: #f8f9fa;\n  color: #343a40;\n  padding: 20px;\n  width: 50px;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 1000;\n}\n\n.sidebar ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.sidebar li {\n  margin: 20px 0;\n}\n\n.sidebar a {\n  text-decoration: none;\n}\n\n.icon {\n  font-size: 24px;\n}\n\n.link .icon {\n  color: #6c757d; /* Standardfarbe der Icons */\n}\n\n.activeLink .icon {\n  color: rgb(11, 104, 175); /* Farbe der aktiven Icons */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__TTYAt`,
	"icon": `Sidebar_icon__1v8Ln`,
	"link": `Sidebar_link__mVAGN`,
	"activeLink": `Sidebar_activeLink__NLkVB`
};
export default ___CSS_LOADER_EXPORT___;
