import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tile from "./Tile";
import { getDockerContainers } from "../api";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import styles from "./ServerDetail.module.css";

function ServerDetail() {
  const { serverName } = useParams();
  const [containers, setContainers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredContainers, setFilteredContainers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContainers = async () => {
      try {
        const data = await getDockerContainers(serverName);

        // Sort containers: active instances first, then alphabetically within each group
        const sortedData = data.sort((a, b) => {
          if (a.active_instance_count > 0 && b.active_instance_count > 0) {
            return a.container_name.localeCompare(b.container_name);
          }
          if (a.active_instance_count === 0 && b.active_instance_count === 0) {
            return a.container_name.localeCompare(b.container_name);
          }
          return b.active_instance_count - a.active_instance_count;
        });

        setContainers(sortedData);
        setFilteredContainers(sortedData); // Initially show all containers
      } catch (error) {
        console.error("Error fetching containers:", error);
      }
    };

    fetchContainers();
  }, [serverName]);

  useEffect(() => {
    const results = containers.filter((container) =>
      container.container_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredContainers(results);
  }, [searchText, containers]);

  const handleTileClick = (id) => {
    navigate(`/server/${serverName}/${id}`);
  };

  return (
    <div className={styles.serverDetail}>
      <input
        type="text"
        placeholder="Search containers..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className={styles.searchBar}
      />
      <div className={styles.tilesContainer}>
        {filteredContainers.map((container) => (
          <Tile
            key={container.id}
            title={container.container_name}
            details={`Instances: ${container.instance_count} | Active: ${container.active_instance_count}`}
            icon={faBox}
            onClick={() => handleTileClick(container.id)}
          />
        ))}
      </div>
    </div>
  );
}

export default ServerDetail;
