// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WebcrawlerDetail.module.css */
.WebcrawlerDetail_webcrawlerDetail__I8Mss {
  padding: 20px;
  background-color: #f8f9fa;
}

.WebcrawlerDetail_tilesContainer__DNawg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/WebcrawlerDetail.module.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,WAAW;AACb","sourcesContent":["/* WebcrawlerDetail.module.css */\n.webcrawlerDetail {\n  padding: 20px;\n  background-color: #f8f9fa;\n}\n\n.tilesContainer {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webcrawlerDetail": `WebcrawlerDetail_webcrawlerDetail__I8Mss`,
	"tilesContainer": `WebcrawlerDetail_tilesContainer__DNawg`
};
export default ___CSS_LOADER_EXPORT___;
