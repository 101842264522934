import axios from "axios";

// Funktion, um den Token aus dem localStorage zu holen
const getToken = () => localStorage.getItem("token");

export const getDockerServers = async () => {
  try {
    const response = await axios.get(
      "https://docker-system.vx2.vj0.de/docker-servers/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${getToken()}`, // Token hinzufügen
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching docker servers:", error);
    throw error;
  }
};

export const getDockerContainers = async (serverName) => {
  try {
    const response = await axios.get(
      `https://docker-system.vx2.vj0.de/docker-containers?server_name=${serverName}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${getToken()}`, // Token hinzufügen
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching docker containers:", error);
    throw error;
  }
};

export const getWebCrawlers = async (containerId) => {
  try {
    const response = await axios.get(
      `https://docker-system.vx2.vj0.de/docker-containers/${containerId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${getToken()}`, // Token hinzufügen
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching web crawlers:", error);
    throw error;
  }
};
