import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./WebcrawlerTile.module.css";

function Tile({ title, details, onClick }) {
  return (
    <div className={styles.tile} onClick={onClick}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.details}>{details}</p>
    </div>
  );
}

export default Tile;
