import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import MainFrame from "./components/MainFrame";
import Login from "./components/Login";
import styles from "./App.module.css";

function App() {
  const [token, setToken] = useState(localStorage.getItem("token") || null);

  const handleLogin = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
  };

  return (
    <Router>
      <div className={styles.App}>
        {!token ? (
          // Wenn der Benutzer nicht eingeloggt ist, zeigen wir die Login-Seite an
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        ) : (
          <>
            <Sidebar />
            <div className={styles.AppBody}>
              <Header />
              <MainFrame onLogin={handleLogin} />{" "}
              {/* handleLogin weitergeben */}
              {/* Alle Routen nach dem Login werden hier gerendert */}
            </div>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
