// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainFrame_mainFrame__FCuEb {
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainFrame.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,sBAAsB;AACxB","sourcesContent":[".mainFrame {\n  padding: 20px;\n  width: 100%;\n  height: 100%;\n  background-color: #f8f9fa;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainFrame": `MainFrame_mainFrame__FCuEb`
};
export default ___CSS_LOADER_EXPORT___;
